import React, { PureComponent } from 'react';
import { connect as mqttConnect, MqttClient, OnMessageCallback, OnErrorCallback } from 'mqtt';
import { MQTTResponse } from '../reducers';
import { ALL_TOPIC } from '../reducers/mqtt';

type Props = {
	topic: string;
	trackAll: boolean;
	onReceive: (data: MQTTResponse) => void;
	clearBusMarker: () => void;
};

class Mqtt extends PureComponent<Props> {
	mqttClient: MqttClient;
	constructor(props: Props) {
		super(props);
		this.mqttClient = mqttConnect('mqtts://mqtt.hsl.fi:443');
		this.mqttClient.on('connect', this.onConnect);
		this.mqttClient.on('message', this.onMessage);
		this.mqttClient.on('error', this.onError);
	}

	onConnect = () => {
		if (this.props.topic !== ALL_TOPIC || this.props.trackAll) {
			this.connect(this.props.topic);
		}
	};

	disConnect = (topic: string) => {
		this.mqttClient.unsubscribe(topic, () => {
			this.props.clearBusMarker();
		});
	};

	connect = (topic: string) => {
		this.mqttClient.subscribe(topic, err => {
			if (err) {
			}
		});
	};

	onError: OnErrorCallback = err => {};

	onMessage: OnMessageCallback = (topic, message) => {
		const res = JSON.parse(message.toString());
		this.props.onReceive(res.VP);
	};

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.topic !== this.props.topic) {
			this.disConnect(this.props.topic);
			this.connect(nextProps.topic);
		}
	}

	render() {
		return <div />;
	}
}

export default Mqtt;
