import * as React from 'react';

export interface InfoBarProps {}

const InfoBar: React.SFC<InfoBarProps> = () => {
	return (
		<div
			style={{
				width: '100%',
				margin: '0 auto',
				color: 'white',
				top: '300px'
			}}
		>
			<h2>Helbus - Helsinki Bus Tracker</h2>
			<p>Track HSL bus/tram/train in real time.</p>
			<hr
				style={{
					background: '#f3f3f3',
					height: '1px',
					border: '0px'
				}}
			/>
			<p>
				<span
					style={{
						fontWeight: 'bold',
						marginRight: '5px'
					}}
				>
					Authors:
				</span>
				<a
					href="http://loveyoung.me"
					target="_blank"
					style={{
						color: 'white',
						textDecoration: 'underline'
					}}
				>
					Li@ng
				</a>{' '}
				&amp; Hengda Xiao
			</p>
			<p>
				<span
					style={{
						fontWeight: 'bold',
						marginRight: '5px'
					}}
				>
					Contact:
				</span>
				<a
					href="mailto:feedback@helbus.live"
					style={{
						color: 'white'
					}}
				>
					feedback@helbus.live
				</a>
			</p>
		</div>
	);
};

export default InfoBar;
