import React, { PureComponent } from 'react';
import Map from './components/Map';
import RouterSelector from './components/RouteSelector';
import InfoBar from './components/InfoBar';
import './App.css';
import Spinner from './components/Spinner';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import LeftArrowIcon from './components/Icons/LeftArrow';
import RightArrowIcon from './components/Icons/RightArrow';

const firebaseConfig = {
	apiKey: 'AIzaSyDQCOGADe5bDTrF-6qs2Bcm4I9XYHm_oUY',
	authDomain: 'hsltrack.firebaseapp.com',
	databaseURL: 'https://hsltrack.firebaseio.com',
	projectId: 'hsltrack',
	storageBucket: 'hsltrack.appspot.com',
	messagingSenderId: '941183968719'
};

firebase.initializeApp(firebaseConfig);
// Initialize Cloud Firestore through Firebase
firebase.firestore();

const db = firebase.firestore();

const HELSINKI_GEO = {
	lat: 60.1699,
	lng: 24.9384
};

export const DEFAULT_TOPIC = '/hfp/v1/journey/#';

export interface Geo {
	lat: number;
	lon: number;
}

export interface GeoGroups {
	geometry: Geo[];
}

class App extends PureComponent {
	state = {
		center: null,
		panelClassName: window.innerWidth <= 600 ? 'panel hide' : 'panel',
		mapClassName: window.innerWidth <= 600 ? 'map-wrapper full-width' : 'map-wrapper',
		routes: [],
		selectedRoute: null,
		selectedDirection: null,
		geos: []
	};
	fetchSingleLine(line: string) {
		db.collection('routes_geos')
			.doc(line)
			.get()
			.then(doc => {
				const geos = (doc.data() as any).patterns as GeoGroups;

				this.setState({
					geos
				});
			});
	}

	componentDidMount() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					const { latitude, longitude } = position.coords;

					this.setState({
						center: {
							lat: latitude,
							lng: longitude
						}
					});
				},
				() => {
					this.setState({
						center: HELSINKI_GEO
					});
				},{
					timeout: 5000
				}
			);
		} else {
			this.setState({
				center: HELSINKI_GEO
			});
		}
	}

	togglePanel = () => {
		this.setState({
			panelClassName: this.state.panelClassName === 'panel' ? 'panel hide' : 'panel',
			mapClassName: this.state.mapClassName === 'map-wrapper' ? 'map-wrapper full-width' : 'map-wrapper'
		});
	};

	onSelectHandler = (type: string, option: any) => {
		if (type === 'route') {
			if (option) {
				this.fetchSingleLine(option.value.gtfsId);
				const route = option.value.gtfsId.split(':')[1];
				this.setState({
					selectedRoute: route
				});
			} else {
				const obj = { selectedRoute: null };
				if (window.innerWidth <= 600) {
					(obj as any).panelClassName = 'panel hide';
				}
				this.setState(obj);
			}
		} else if (type === 'direction') {
			if (option) {
				const direction = parseInt(option.value.directionId);
				this.setState({
					selectedDirection: direction
				});
			} else {
				this.setState({
					selectedDirection: null
				});
			}
		}
	};
	render() {
		let topic = DEFAULT_TOPIC;
		if (this.state.selectedRoute !== null) {
			topic = `/hfp/v1/journey/+/+/+/+/${this.state.selectedRoute}/#`;
		}

		if (this.state.selectedDirection !== null) {
			topic = `/hfp/v1/journey/+/+/+/+/${this.state.selectedRoute}/${this.state.selectedDirection! + 1}/#`;
		}

		if (this.state.center) {
			return (
				<div className="app">
					<div className={this.state.panelClassName}>
						<div className="toggle-btn" onClick={this.togglePanel}>
							{this.state.panelClassName === 'panel' ? (
								<LeftArrowIcon
									style={{
										marginTop: '10px'
									}}
								/>
							) : (
								<RightArrowIcon
									style={{
										marginTop: '10px'
									}}
								/>
							)}
						</div>
						<RouterSelector
							onSelect={this.onSelectHandler}
							db={db}
							routes={this.state.routes}
							className="router-selector"
						/>
						<button className="btn mobile-search" onClick={this.togglePanel}>
							Search
						</button>
						<InfoBar />
						<div className="mobile-app-download-wrapper">
							<a
								href="https://itunes.apple.com/fi/app/helbus/id1458224268"
								className="mobile-app-download-icon"
								target="_blank"
							>
								<img src={`${process.env.PUBLIC_URL}/Apple_Store_Icon.png`} alt="" />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=live.hsltrack"
								className="mobile-app-download-icon"
								target="_blank"
							>
								<img src={`${process.env.PUBLIC_URL}/Google_Play_Icon.png`} alt="" />
							</a>
						</div>
					</div>
					<div className={this.state.mapClassName}>
						{this.state.selectedDirection ? (
							<Map center={this.state.center!} topic={topic} geos={this.state.geos[this.state.selectedDirection!]} />
						) : (
							<Map center={this.state.center!} topic={topic} geos={this.state.geos[0]} />
						)}
					</div>
					<div className="clear" />
				</div>
			);
		} else {
			return <Spinner />;
		}
	}
}

export default App;
