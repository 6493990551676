import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker.js';
import store from './store';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';

if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
}
ReactGA.initialize('UA-72512078-3');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
