//@ts-ignore
import React from 'react';
import Select from 'react-select';
import groupBy from 'lodash.groupby';
import ReactGA from 'react-ga';

interface Props {
	className: string;
	routes: any[];
	db: any;
	onSelect: (type: string, option: any) => void;
}

interface Pattern {
	directionId: number;
	headsign: string;
}

const DropdownIndicator = () => {
	return (
		<div
			style={{
				width: '30px'
			}}
		>
			<img src={`${process.env.PUBLIC_URL}/arrow_down.svg`} alt="" />
		</div>
	);
};

const CustomRouteOption = (obj: any) => {
	const { innerProps, isDisabled, label, value, isFocused } = obj;
	return !isDisabled ? (
		<div
			{...innerProps}
			style={{
				padding: '10px',
				display: 'flex',
				background: isFocused ? '#e3e3e3' : '',
				alignItems: 'center'
			}}
		>
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/bus.svg`}
					style={{
						width: '30px',
						padding: '0 10px 0 0'
					}}
				/>
			</div>
			<div>
				<h4
					style={{
						margin: 0,
						marginBottom: '5px'
					}}
				>
					{label}
				</h4>
				<p
					style={{
						margin: 0
					}}
				>
					{value.longName}
				</p>
			</div>
		</div>
	) : null;
};

const CustomDirectionOption = (obj: any) => {
	const { innerProps, label, isFocused } = obj;
	return (
		<div
			{...innerProps}
			style={{
				padding: '10px',
				background: isFocused ? '#e3e3e3' : ''
			}}
		>
			<h4
				style={{
					margin: 0,
					marginBottom: '5px'
				}}
			>
				{label}
			</h4>
		</div>
	);
};

class RouterSelector extends React.PureComponent<Props> {
	state = {
		selectedRoute: null,
		selectedDirection: null,
		routeOptions: [],
		directionOptions: []
	};

	handleRouteChange = (selectedRoute: any) => {
		if (selectedRoute) {
			ReactGA.event({
				category: 'Route',
				action: selectedRoute.value.shortName
			});
			const directionOptions = [];
			const patterns = selectedRoute.value.patterns;
			for (let key in patterns) {
				directionOptions.push({
					label: patterns[key].headsign,
					value: patterns[key]
				});
			}

			this.setState({
				selectedRoute,
				selectedDirection: null,
				directionOptions
			});
		} else {
			ReactGA.event({
				category: 'Route',
				action: 'All'
			});
			this.setState({
				selectedRoute: null,
				selectedDirection: null,
				directionOptions: []
			});
		}
		this.props.onSelect('route', selectedRoute);
	};

	handleDirectionChange = (selectedDirection: any) => {
		if (selectedDirection) {
			this.setState({ selectedDirection });
			this.props.onSelect('direction', selectedDirection);
			ReactGA.event({
				category: 'Route',
				action: (this.state.selectedRoute as any).value.shortName,
				value: selectedDirection
			});
		} else {
			this.setState({ selectedDirection: null });
			ReactGA.event({
				category: 'Route',
				action: 'clear direction'
			});
		}
		this.props.onSelect('direction', selectedDirection);
	};

	getPatterns = (patterns: Pattern[]) => {
		const processedPatterns: any = {};
		patterns.forEach((pattern: Pattern, idx: number) => {
			const headsign = pattern.headsign
				.trim()
				.replace(/ /g, '')
				.toLowerCase();
			if (
				processedPatterns[headsign] === undefined ||
				processedPatterns[headsign].directionId !== pattern.directionId
			) {
				processedPatterns[headsign] = { ...pattern, idx };
			}
		});
		return processedPatterns;
	};

	getOptions = (routes: any) => {
		const obj = groupBy(routes, 'mode');
		const options: any = [];
		Object.keys(obj).forEach(key => {
			const subArr: any = [];
			obj[key].forEach(route => {
				route.patterns = this.getPatterns(route.patterns);
				const option = {
					value: route,
					label: `${route.shortName}`
				};
				subArr.push(option);
			});

			options.push({
				label: key,
				options: subArr
			});
		});

		this.setState({
			routeOptions: options
		});
	};

	componentDidMount() {
		this.props.db
			.collection('routes')
			.get()
			.then((querySnapshot: any) => {
				const routes: any = [];
				querySnapshot.forEach((doc: any) => {
					routes.push(doc.data());
				});
				this.getOptions(routes);
			});
	}

	render() {
		const { selectedRoute, selectedDirection, routeOptions, directionOptions } = this.state;
		const selectStyle = {
			control: (base: any, state: any) => ({
				...base,
				minHeight: '48px !important',
				border: '0 !important',
				boxShadow: '0px 2px 1px rgba(0,0,0, .2) !important',
				'&:hover': {
					border: '0 !important'
				}
			}),
			option: (styles: any) => {
				return {
					...styles
				};
			},
			menu: (styles: any) => {
				return {
					...styles,
					backgroundColor: 'white',
					boxShadow: '1px 2px 6px #888888',
					top: `calc(100% + 1px)`,
					zIndex: 2
				};
			},
			menuList: (styles: any) => {
				return {
					...styles,
					overflowY: 'auto'
				};
			},
			menuPortal: (styles: any) => {
				return {
					...styles,
					marginTop: '10px'
				};
			}
		};

		return (
			<div
				style={{
					width: '100%',
					margin: '0 auto',
					marginTop: '30px'
				}}
			>
				<Select
					value={selectedRoute}
					options={routeOptions}
					onChange={this.handleRouteChange}
					isClearable={true}
					placeholder={'Enter route number... (E.g.: 550)'}
					styles={selectStyle}
					components={{
						DropdownIndicator,
						Option: CustomRouteOption,
						IndicatorSeparator: () => null
					}}
					theme={theme => ({
						...theme,
						borderRadius: 0,
						border: 0,
						colors: {
							...theme.colors,
							primary25: 'lightGray',
							primary: 'Gainsboro'
						}
					})}
				/>

				<br />
				{this.state.selectedRoute && (
					<div>
						<Select
							value={selectedDirection}
							options={directionOptions}
							onChange={this.handleDirectionChange}
							placeholder={'Choose Destination...'}
							isClearable={true}
							styles={selectStyle}
							components={{
								DropdownIndicator,
								Option: CustomDirectionOption,
								IndicatorSeparator: () => {
									return null;
								}
							}}
							theme={theme => ({
								...theme,
								borderRadius: 0,
								border: 0,
								colors: {
									...theme.colors,
									primary25: 'lightGray',
									primary: 'Gainsboro'
								}
							})}
						/>
						<br />
					</div>
				)}
			</div>
		);
	}
}

export default RouterSelector;
