import React, { PureComponent } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import Mqtt from './Mqtt';
import { GeoGroups, DEFAULT_TOPIC } from '../App';

import { MQTTResponse } from '../reducers';

interface Props {
	center: Coords;
	topic: string;
	geos?: GeoGroups;
}

class Map extends PureComponent<Props> {
	busMarkers: any = {};
	infoWindow: any;
	infoWindowBusKey: any;
	map = null;
	maps = null;
	routePath = null;
	switchTopic = false;
	currentLocation = null;

	handleApiLoaded = (map: any, maps: any) => {
		this.map = map;
		this.maps = maps;
		maps.event.addListener(map, 'click', () => {
			this.closeOtherInfoWindow();
		});
		this.regCurrentLocation();
	};

	getCoords = (data: MQTTResponse) => {
		return {
			lat: data.lat,
			lng: data.long
		};
	};

	getKey = (data: MQTTResponse) => {
		return `${data.desi}-${data.dir}-${data.veh}`;
	};

	isMarkerExist = (data: MQTTResponse) => {
		const key = this.getKey(data);
		return this.busMarkers[key] !== undefined;
	};

	handleMQTT = (data: MQTTResponse) => {
		if (this.map && this.maps) {
			if (this.isMarkerExist(data)) {
				this.updateMarker(data);
			} else {
				this.createMarker(data);
			}
		}
	};

	clearBusMarker = () => {
		if (this.switchTopic) {
			for (let idx in this.busMarkers) {
				this.busMarkers[idx].setMap(null);
			}
			this.busMarkers = {};
			this.switchTopic = false;
		}
	};

	closeOtherInfoWindow = () => {
		if (this.infoWindow) this.infoWindow.close();
	};

	createMarker = (data: MQTTResponse) => {
		const { lat, lng } = this.getCoords(data);
		if (lat && lng) {
			//const markerIcon = `${process.env.PUBLIC_URL}/marker.png`;

			const systemMarker = {
				path: (this.maps as any).SymbolPath.FORWARD_CLOSED_ARROW,
				scale: 3.5,
				rotation: data.hdg,
				strokeColor: '#e91e63',
				strokeWeight: 2.5,
				fillOpacity: 1,
				fillColor: '#e91e63'
			};

			// var markerIcon = {
			// 	path: 'M0 64 L32 42 L64 64 L32 0 Z',
			// 	fillColor: '#2196F3',
			// 	strokeColor: 'white',
			// 	fillOpacity: 0.8,
			// 	scale: 0.8,
			// 	rotation: data.hdg
			// };
			var marker = new (this.maps as any).Marker({
				position: { lat, lng },
				map: this.map,
				icon: systemMarker
			});
			const busKey = this.getKey(data);
			this.busMarkers[busKey] = marker;
			marker.addListener('click', () => {
				this.closeOtherInfoWindow();
				const infoWindowContent = this.getInfoWindowContent(data);
				this.infoWindowBusKey = busKey;
				this.infoWindow = new (this.maps as any).InfoWindow({
					content: infoWindowContent
				});
				this.infoWindow.open(this.map, this.busMarkers[busKey]);
			});
		}
	};

	getInfoWindowContent = (data: any) => {
		let delayString;
		if (data.dl) {
			if (parseInt(data.dl) > 0) {
				if (data.dl < 100) {
					delayString = `Early: ${data.dl}s`;
				} else {
					delayString = `Early: ${(data.dl / 60).toFixed(2)}mins`;
				}
			} else {
				if (Math.abs(data.dl) < 100) {
					delayString = `Delayed: ${Math.abs(data.dl)}s`;
				} else {
					delayString = `Delayed: ${(Math.abs(data.dl) / 60).toFixed(2)}mins`;
				}
			}
		}

		return `
					<ul style="
						list-style-type: none;
						padding: 0;
						margin: 0;
						font-size: 14px;
						width: 200px;
					">
						<li>No: ${data.desi}</li>
						<li>Speed: ${data.spd ? (data.spd * 3.6).toFixed(2) : 0} km/h</li>
						${delayString !== undefined ? `<li>${delayString}</li>` : ''}
					</ul>
					`;
	};

	updateMarker = (data: MQTTResponse) => {
		const { lat, lng } = this.getCoords(data);
		const key = this.getKey(data);
		if (lat && lng) this.busMarkers[key].setPosition({ lat, lng });
		if (this.infoWindow && this.infoWindowBusKey === key) {
			this.infoWindow.setContent(this.getInfoWindowContent(data));
		}
	};

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.topic !== this.props.topic) {
			this.switchTopic = true;
		}
	}

	componentWillUpdate(newProps: any) {
		if (newProps.topic === DEFAULT_TOPIC) {
			(this.routePath as any).setMap(null);
			return;
		}
		if (newProps.geos && this.maps && this.map) {
			if (this.routePath) {
				(this.routePath as any).setMap(null);
			}
			const bounds = new (this.maps as any).LatLngBounds();
			this.routePath = new (this.maps as any).Polyline({
				path: newProps.geos.geometry.map((geo: any) => {
					const coord = {
						lat: geo.lat,
						lng: geo.lon
					};
					bounds.extend(coord);
					return coord;
				}),
				geodesic: true,
				strokeColor: '#2196F3',
				strokeOpacity: 1.0,
				strokeWeight: 3
			});

			(this.routePath as any).setMap(this.map);
			bounds.getCenter();
			(this.map as any).fitBounds(bounds);
		}
	}

	regCurrentLocation = () => {
		if (this.currentLocation == null) {
			this.currentLocation = new (this.maps as any).Marker({
				position: { lat: this.props.center.lat, lng: this.props.center.lng },
				map: this.map,
				icon: {
					path: (this.maps as any).SymbolPath.CIRCLE,
					scale: 7,
					strokeColor: '#C8D6EC',
					strokeWeight: 2.5,
					fillOpacity: 1,
					fillColor: '#4285F4'
				}
			});
		}

		navigator.geolocation.watchPosition(
			position => {
				const { latitude, longitude } = position.coords;

				(this.currentLocation as any).setPosition({
					lat: latitude,
					lng: longitude
				});
			},
			() => {},
			{
				enableHighAccuracy: false,
				timeout: 5000,
				maximumAge: 0
			}
		);
	};

	render() {
		return (
			<div style={{ height: '100vh', width: '100%' }}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: 'AIzaSyBXy6VGyQlLMU9cFIgPh3AzCva_zXY2YJY' }}
					center={this.props.center}
					defaultZoom={15}
					options={{
						gestureHandling: 'greedy',
						zoomControl: false
					}}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
				/>
				<Mqtt
					trackAll={true}
					onReceive={this.handleMQTT}
					clearBusMarker={this.clearBusMarker}
					topic={this.props.topic}
				/>
			</div>
		);
	}
}

export default Map;
