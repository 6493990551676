import { Action } from 'redux';
import actionCreatorFactory, { isType } from 'typescript-fsa';

export interface State {
	topic: string;
}

const actionCreator = actionCreatorFactory('mqtt');
export const subscribe = actionCreator<string>('SUBSCRIBE');

export const ALL_TOPIC = '/hfp/v1/journey/#';
const initialState = {
	topic: ALL_TOPIC
};

export default function(state: State = initialState, action: Action): State {
	if (isType(action, subscribe)) {
		return { ...state, topic: action.payload };
	} else {
		return state;
	}
}
