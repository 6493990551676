import React from 'react';

const RightArrow = (props: any) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="currentColor" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
);

export default RightArrow;
