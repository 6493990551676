import React from 'react';

const LeftArrow = (props: any) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="currentColor" />
		<path d="M0 0h24v24H0z" fill="none" />
	</svg>
);

export default LeftArrow;
