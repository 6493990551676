import { combineReducers } from 'redux';
import mqtt, { State as MqttState } from './mqtt';

export interface MQTTResponse {
	desi?: string; // Route number visible to passengers.
	dir?: string; // Route direction of the trip. After type conversion matches direction_id in GTFS and the topic. Either "1" or "2"
	oper?: number; // Unique ID of the operator running the trip. The unique ID does not have prefix zeroes here.

	// Vehicle number that can be seen painted on the side of the vehicle, often next to the front door.
	// Different operators may use overlapping vehicle numbers.
	// Matches vehicle_number in the topic except without the prefix zeroes.
	veh?: number;

	tst?: string; // UTC timestamp from the vehicle in ISO 8601 format as output by date --utc "+%Y-%m-%dT%H:%M:%SZ"
	tsi?: number; // Unix time in seconds, matching tst.
	spd?: number; // Speed of the vehicle, in meters per second (m/s).
	hdg?: number; // Heading of the vehicle, in degrees (⁰) starting clockwise from geographic north. Valid values are on the closed interval [0, 360].
	lat?: number; // WGS 84 latitude in degrees. null if there is no GPS fix.
	long?: number; // WGS 84 longitude in degrees. null if there is no GPS fix.
	acc?: number; // Acceleration (m/s^2), calculated from the speed on this and the previous message. Negative values indicate that the speed of the vehicle is decreasing.
	dl?: number; // Offset from the scheduled timetable in seconds (s). Negative values indicate lagging behind the schedule, positive values running ahead of schedule.
	odo?: number; // The odometer reading in meters (m) since the start of the trip. Currently the values not very reliable.
	drst?: number; // Door status. 0 if all the doors are closed, 1 if any of the doors are open.
	/**
	 * Operating day of the trip.
	 * The exact time when an operating day ends depends on the route.
	 * For most routes, the operating day ends at 4:30 AM on the next day.
	 * In that case, for example, the final moment of the operating day "2018-04-05" would be at 2018-04-06T04:30 local time.
	 */
	oday?: string;

	jrn?: number; // Internal journey descriptor, not meant to be useful for external use.
	line?: number; // Internal line descriptor, not meant to be useful for external use.

	/**
	 *Scheduled start time of the trip.
	 i.e. the scheduled departure time from the first stop of the trip.
	 The format follows %H:%M in 24-hour local time, not the 30-hour overlapping operating days present in GTFS.
	 Matches start_time in the topic.
	 */
	start?: string;
}

export interface RootState {
	mqtt: MqttState;
}

export default combineReducers<RootState>({
	mqtt
});
